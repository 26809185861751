import * as React from "react"
import Legal from "../@lekoarts/gatsby-theme-cara/components/legal"

const Terms = () => (

      <Legal />

)

export default Terms
